import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../common/header';
import Footer from'../common/footer';
import Galleryview from'../common/gallery';
import home5 from "../../assets/img/banner_slider/home5.png";
import window_banner from "../../assets/img/window_banner.png";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faAngleRight, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

export default class acp extends React.Component {

  render() {
    return (
      <main>
      <Header></Header>
      <section className="main_heading">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="inner_heading">
                  <h2>Products</h2>
                  <span></span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bside_link text-right">
                <ul>
                <li> <Link to="/"><a>Home</a></Link></li><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></i>
                <li className="page_link"><a href="acp">ACP</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="window_banner liset_section for_window">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div class="dark_head product_heading">
                <h2>Aluminium composite panels sheets </h2>
                <span></span>
              </div>
              <img className="img-fluid" src={home5} alt=""></img>
              <p><b>Aluminium composite panels sheets</b> (ACP sheets) are flat panels consisting of two thin coil-coated aluminium sheets bonded to a non-aluminium core. ACP sheets, made of aluminium composite material (ACM), are frequently used for external cladding or facades of buildings, insulation, and signage.</p>
              <p>ACP is made from lightweight aluminium with hard insulation. The aluminium composite cladding is used for exterior cladding, column covers, fascias and canopies, interior wall and partition panels, parapets, false ceilings, louvers and sunshades.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="about_features galass_list liset_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div class="dark_head product_heading">
                <h2>Benefits of using ACP</h2>
                <span></span>
              </div>
              <ul className="about_list">
                <li><span>1</span>	Longevity</li>
                <li><span>2</span>	Cost-Effective</li>
                <li><span>3</span>	Safety</li>
                <li><span>4</span>	Easy Installation</li>
                <li><span>5</span>	Trouble-Free Maintenance</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="about_features window_list glass_list liset_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div class="dark_head product_heading">
                <h2>Types of ACPs used for facades, interiors, and signage:</h2>
                <span></span>
              </div>
              <p className="acp_cont">Exterior usage of ACP Sheet: This is popularly used in the IT sector, malls and showrooms, residential and commercial building, corporate bodies, hospitals and cultural rooms at large.</p>
              <ul className="about_list for_listdote">
                <li><span></span>	Highly durable and flexible materials with zero crack or dent</li>
                <li><span></span>	Eco-friendly unit and can be recycled</li>
                <li><span></span>  Unmatched flatness and grave by the thickness</li>
                <li><span></span>	Can be installed on soffit columns, facades, and canopies</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="about_features window_list glass_list liset_section list_bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div class="dark_head product_heading">
                <h2>Interior usages of ACP Sheets: These innovative ACPs are used in</h2>
                <span></span>
              </div>
              <ul className="about_list for_listdote">
                <li><span></span> Modular panel</li>
                <li><span></span> False ceiling</li>
                <li><span></span> Kitchen drawers</li>
                <li><span></span> Creative wall claddings</li>
                <li><span></span> Modular kitchens</li>
                <li><span></span> Furniture and skylights</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    
              {/* <Galleryview></Galleryview> */}
            
      <Footer></Footer>
      </main>
    );
  }
}