import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../common/header';
import Footer from'../common/footer';
import director_1 from "../../assets/img/director_1.png";
import director_2 from "../../assets/img/director_2.png";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

export default class directors extends React.Component {

  render() {
    return (
      <main>
      <Header></Header>
      <section className="main_heading">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="inner_heading">
                  <h2>Board of Directors </h2>
                  <span></span>
              </div>
            </div>
            <div className="col-md-6">
              <div class="bside_link text-right">
                <ul>
                <li> <Link to="/"><a>Home</a></Link></li><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></i>
                  <li class="page_link"><a href="directors">Board of Directors </a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="about_section1 section_padding">
        <div class="container">
            <div class="abu_aside">
              <div class="row">
                <div class="col-md-6">
                  <div class="director_img">
                    <img className="img-fluid" src={director_1} alt=""></img>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="director_detail">
                    <div class="dark_head sec_head director_name">
                      <h2>Mr. Vishal Singh Chouhan</h2>
                      <span></span>
                    </div>
                    <h4>Managing Director</h4>
                    <p>Having 15 years experience of core technical expertise in facade solutions</p>
                    <h5>Contact: +91 9826823312</h5>
                    <h5>Email: vishal@brightget.com</h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="abu_bside">
              <div class="row">
                <div class="col-md-6">
                  <div class="director_detail">
                    <div class="dark_head sec_head director_name">
                      <h2>Mr. Pradeep Jaiswal</h2>
                      <span></span>
                    </div>
                    <h4>Managing Director</h4>
                    <p>Having 15 years experience in project management & customer sales and servicing</p>
                    <h5>Contact: +91 9301992993</h5>
                    <h5>Email: pradeep@brightget.com</h5>
                  </div>
                </div>
                <div class="col-md-6">
                  <div className="director_img1">
                    <img className="img-fluid" src={director_2} alt=""></img>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
      <Footer></Footer>
      </main>
    );
  }
}