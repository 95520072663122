import glass1 from "../img/glass_1.jpg";
import glass2 from "../img/glass_2.jpg";
import glass3 from "../img/glass_3.jpg";
import glass4 from "../img/glass_4.jpg";
import glass5 from "../img/glass_5.jpg";
import glass6 from "../img/glass_6.jpg";
import glass7 from "../img/glass_7.jpg";
import glass8 from "../img/glass_8.jpg";
import glass9 from "../img/glass_9.jpg";
import glass10 from "../img/glass_10.jpg";
import glass11 from "../img/glass_11.jpg";
import glass12 from "../img/glass_12.jpg";
import glass13 from "../img/glass_13.jpg";



export const photos = [
    
    {
      src: glass1,
      width: 4,
      height: 3
    },
    {
      src: glass2,
      width: 3,
      height: 4
    },
    {
      src: glass3,
      width: 4,
      height: 3
    },
    {
      src: glass4,
      width: 4,
      height: 3
    },
    {
        src: glass5,
        width: 4,
        height: 3
      },
      {
        src: glass6,
        width: 1,
        height: 1
      },
      {
        src: glass7,
        width: 3,
        height: 4
      },
      {
        src: glass8,
        width: 3,
        height: 4
      },
      {
        src: glass9,
        width: 4,
        height: 3
      },
      {
        src: glass10,
        width: 1,
        height: 1
      },
      {
        src: glass11,
        width: 3,
        height: 4
      },
      {
        src: glass12,
        width: 3,
        height: 4
      },
      {
        src: glass13,
        width: 3,
        height: 4
      }
          
  ];
  