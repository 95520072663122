import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../common/header';
import Footer from'../common/footer';
import Testimonial from'../home/testimonial';
import Bannerslider from'../home/bannerslider';
import about from "../../assets/img/about.png";
import director_1 from "../../assets/img/director_1.png";
import director_2 from "../../assets/img/director_2.png";
import pro_1 from "../../assets/img/glass_2.jpg";
import pro_3 from "../../assets/img/acp_1.jpg";
import pro_4 from "../../assets/img/acp_49.jpg";
import pro_5 from "../../assets/img/project.png";
import pro_6 from "../../assets/img/acp_41.jpg";
import pro_2 from "../../assets/img/glass_8.jpg";
import my_project from "../../assets/img/project.png";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Projectslider from './proslide';

export default class Home extends React.Component {

  render() {
    return (
      <main>
      <Header></Header>
      <Bannerslider></Bannerslider>
      <section className="about_us section_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div class="about_aside">
                <div className="dark_head sec_head">
                  <h2>About Us</h2>
                  <span></span>
                </div>
                <div className="about_cont">
                  <p>Windows and doors are amongst the most frequently stressed or (those are used frequently) components of a house. It handel Wind and rain, strong temperature fluctuations, environmental influences and mechanical stresses constantly.</p>
                  <p>Aluminium has been used as a construction material for decades. What began with minimal uses in panels and frames of small houses and buildings has grown to become a style statement providing metallic sheen and class to both residential and commercial spaces.</p>
                  <p>Today, aluminium is the ultimate answer to all your window and door needs, regardless of their nature.</p>
                </div>
                <div className="about_btn">
                  <button className="all_btn">Read More</button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
                <div className="about_img">
                   <img src={about} alt=""></img>
                </div>
            </div>
          </div>
        </div>
      </section>
      <section className="director section_padding">
        <div className="director_main">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="dark_head text-center">
                  <h2>Board of Directors</h2>
                  <span></span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="director_aside">
                  <img className="img-fluid" src={director_1} alt=""></img>
                  <div className="director_detail">
                    <div class="dark_head sec_head director_name">
                      <h2>Mr. Vishal Singh Chouhan</h2>
                      <span></span>
                    </div>
                    <h4>Managing Director</h4>
                    <p>Having 15 years experience of core technical expertise in facade solutions</p>
                    <h5>Contact: +91 9826823312</h5>
                    <h5>Email: vishal@brightget.com</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="director_bside">
                    <img className="img-fluid" src={director_2} alt=""></img>
                    <div className="director_detail">
                      <div className="dark_head sec_head director_name">
                        <h2>Mr. Pradeep Jaiswal</h2>
                        <span></span>
                      </div>
                      <h4>Managing Director</h4>
                      <p>Having 15 years experience in project management & customer sales and servicing.</p>
                      <h5>Contact: +91 9301992993</h5>
                      <h5>Email: pradeep@brightget.com</h5>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="project section_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="product_detail">
                <div className="dark_head roduct_heading">
                  <h2>Products</h2>
                  <span></span>
                </div>
                <p>We regularly expand the range of services to make our customers feel comfortable.
                    We regularly expand the range of services to make our customers feel comfortable.We regularly expand the range of services to make our customers feel comfortable.</p>
                <button class="all_btn">Order Now</button>
              </div>
            </div>
            <div className="col-md-3">
              <div class="tab">
                <div class="effect effect-six">
                    <img className="img-fluid" src={pro_1} alt=""></img>
                    <div className="project_cont">
                        <p>Aluminium Windows</p>
                        <a href="#">Read More</a><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></i>
                    </div>
                  </div>
              </div>
            </div>
            <div className="col-md-3">
              <div class="tab">
                <div class="effect effect-six">
                    <img className="img-fluid" src={pro_2} alt=""></img>
                    <div className="project_cont">
                        <p>UPVC Windows </p> 
                        <a href="#">Read More</a><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></i>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className="project_sec">
            <div className="row">
              <div className="col-md-3">
                <div class="tab">
                  <div class="effect effect-six">
                      <img className="img-fluid" src={pro_3} alt=""></img>
                      <div className="project_cont">
                          <p>Aluminium Doors</p>
                          <a href="#">Read More</a><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></i>
                      </div>
                    </div>
                </div>
              </div>
              <div className="col-md-3">
                <div class="tab">
                  <div class="effect effect-six">
                      <img className="img-fluid" src={pro_4} alt=""></img>
                      <div className="project_cont">
                          <p>UPVC Doors</p>
                          <a href="#">Read More</a><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></i>
                      </div>
                    </div>
                </div>
              </div>
              <div className="col-md-3">
                <div class="tab">
                  <div class="effect effect-six">
                      <img className="img-fluid" src={pro_5} alt=""></img>
                      <div className="project_cont">
                          <p>Glass</p>
                          <a href="#">Read More</a><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></i>
                      </div>
                    </div>
                </div>
              </div>
              <div className="col-md-3">
                <div class="tab">
                  <div class="effect effect-six">
                      <img className="img-fluid" src={pro_6} alt=""></img>
                      <div className="project_cont">
                          <p>ACP</p> 
                          <a href="#">Read More</a><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></i>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
      <Projectslider></Projectslider>
      <section className="section_padding home_testimonial">
        <Testimonial></Testimonial>
      </section>
      <Footer></Footer>
      </main>
    );
  }
}