
import React from 'react';
import Slider from "react-slick"; 
import pro_1 from "../../assets/img/acp_41.jpg";
import pro_3 from "../../assets/img/acp_4.jpg";
import pro_4 from "../../assets/img/acp_35.jpg";
import pro_5 from "../../assets/img/glass_8.jpg";
import pro_6 from "../../assets/img/wd_9.jpeg";
import pro_2 from "../../assets/img/wd_2.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import my_project from "../../assets/img/project_1.png";
class Projectslider extends React.Component {
  

  render() {
        var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        autoplay:true,
        autoplaySpeed:3000,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        className: "slides",
        responsive: [
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
             
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }]
        };

   

    return (
        
<section className="project section_padding banner_slider pro_slider banner_slider1">
    <div className="slidepro_main">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
              <div class="dark_head text-center set_slide_heading"><h2>Our Project</h2><span></span></div>
     
            <Slider {...settings}>
  
                          
              <div class="tab">
                <div className="slide_deta">
                <div class="effect effect-six">
                  <img className="img-fluid" src={pro_4} alt=""></img>  
                  <div className="project_cont">
                      <p>UPVC Doors</p>
                      <span>UPVC or PVCu is a form of plastic and stands for Unplasticised Polyvinyl Chloride ...</span>
                      <div>
                        <button class="all_btn project_btn">Order Now</button>
                      </div>
                  </div>
                </div>
                </div>
              </div>
              <div class="tab">
                <div className="slide_deta">
                <div class="effect effect-six">
                  <img className="img-fluid" src={pro_5} alt=""></img>  
                  <div className="project_cont">
                      <p>UPVC Doors</p>
                      <span>UPVC or PVCu is a form of plastic and stands for Unplasticised Polyvinyl Chloride ...</span>
                      <div>
                        <button class="all_btn project_btn">Order Now</button>
                      </div>
                  </div>
                </div>
                </div>
              </div>
              <div class="tab">
                <div className="slide_deta">
                <div class="effect effect-six">
                  <img className="img-fluid" src={pro_3} alt=""></img>  
                  <div className="project_cont">
                      <p>UPVC Doors</p>
                      <span>UPVC or PVCu is a form of plastic and stands for Unplasticised Polyvinyl Chloride ...</span>
                      <div>
                        <button class="all_btn project_btn">Order Now</button>
                      </div>
                  </div>
                </div>
                </div>
              </div>
              <div class="tab">
                <div className="slide_deta">
                <div class="effect effect-six">
                  <img className="img-fluid" src={pro_2} alt=""></img>  
                  <div className="project_cont">
                      <p>UPVC Doors</p>
                      <span>UPVC or PVCu is a form of plastic and stands for Unplasticised Polyvinyl Chloride ...</span>
                      <div>
                        <button class="all_btn project_btn">Order Now</button>
                      </div>
                  </div>
                </div>
                </div>
              </div>
              <div class="tab">
                <div className="slide_deta">
                <div class="effect effect-six">
                  <img className="img-fluid" src={pro_1} alt=""></img>  
                  <div className="project_cont">
                      <p>UPVC Doors</p>
                      <span>UPVC or PVCu is a form of plastic and stands for Unplasticised Polyvinyl Chloride ...</span>
                      <div>
                        <button class="all_btn project_btn">Order Now</button>
                      </div>
                  </div>
                </div>
                </div>
              </div>
              <div class="tab">
                <div className="slide_deta">
                <div class="effect effect-six">
                  <img className="img-fluid" src={pro_6} alt=""></img>  
                  <div className="project_cont">
                      <p>UPVC Doors</p>
                      <span>UPVC or PVCu is a form of plastic and stands for Unplasticised Polyvinyl Chloride ...</span>
                      <div>
                        <button class="all_btn project_btn">Order Now</button>
                      </div>
                  </div>
                </div>
                </div>
              </div>
               </Slider>
            {/* </div>       
          </div>
          </div> */}
          </div>
        </div>
      </div>
    </div>
</section>  
);
  }
}

export default Projectslider
