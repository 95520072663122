import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../common/header';
import Footer from'../common/footer';
import my_project from "../../assets/img/project.png";
import pro_1 from "../../assets/img/acp_41.jpg";
import pro_3 from "../../assets/img/acp_4.jpg";
import pro_4 from "../../assets/img/acp_35.jpg";
import pro_5 from "../../assets/img/wd_10.jpg";
import pro_6 from "../../assets/img/wd_9.jpeg";
import pro_2 from "../../assets/img/acp_49.jpg";
import {Link} from "react-router-dom"
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faAngleRight, faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default class products extends React.Component {

  render() {
    return (
      <main>
      <Header></Header>
      <section className="main_heading">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="inner_heading">
                  <h2>Products</h2>
                  <span></span>
              </div>
            </div>
            <div className="col-md-6">
              <div class="bside_link text-right">
                <ul>
                <li> <Link to="/"><a>Home</a></Link></li><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></i>
                <li class="page_link"><a href="#">Products</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="about_section1 section_padding">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
                <div class="page_heading">
                    <h2>Products </h2>
                    <span></span>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="project_img">
                <div class="tab">
                <Link to="aluminium_windows">
                  <div class="effect effect-six">
                      <img className="img-fluid" src={pro_3} alt=""></img>
                      <div className="project_cont">
                        <p>Aluminium Windows</p>
                        <a href="#">Read More</a><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></i>
                      </div>
                  </div>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="project_img">
                <div class="tab">
                <Link to="upvc_windows">
                  <div class="effect effect-six">
                      <img className="img-fluid" src={pro_2} alt=""></img>
                      <div className="project_cont">
                        <p>UPVC Windows </p>
                        <a href="#">Read More</a><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></i>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="project_img">
                <div class="tab">
                <Link to="aluminum_doors">
                  <div class="effect effect-six">
                      <img className="img-fluid" src={pro_1} alt=""></img>
                      <div className="project_cont">
                        <p>Aluminium Doors</p>
                        <a href="#">Read More</a><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></i>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
          <div class="col-md-4">
              <div class="project_img">
                <div class="tab">
                <Link to="glass">
                  <div class="effect effect-six">
                      <img className="img-fluid" src={pro_4} alt=""></img>
                      <div className="project_cont">
                        <p>Glass</p>
                        <a href="#">Read More</a><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></i>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="project_img">
                <div class="tab">
                <Link to="upvc_doors">
                  <div class="effect effect-six">
                      <img className="img-fluid" src={pro_5} alt=""></img>
                      <div className="project_cont">
                        <p>UPVC Doors</p>
                        <a href="#">Read More</a><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></i>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="project_img">
                <div class="tab">
                <Link to="acp">
                  <div class="effect effect-six">
                      <img className="img-fluid" src={pro_6} alt=""></img>
                      <div className="project_cont">
                        <p>ACP</p>
                        <a href="#">Read More</a><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></i>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
      </main>
    );
  }
}