import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
 import { photos } from "../../assets/js/wd_album.js";

 function Wd_gallery() {
        const [currentImage, setCurrentImage] = useState(0);
        const [viewerIsOpen, setViewerIsOpen] = useState(false);
      
        const openLightbox = useCallback((event, { photo, index }) => {
          setCurrentImage(index);
          setViewerIsOpen(true);
        }, []);
      
        const closeLightbox = () => {
          setCurrentImage(0);
          setViewerIsOpen(false);
        };
  
        return(
            <section className="product_gallery list_bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
            <div >
            <Gallery photos={photos} onClick={openLightbox} />
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal className="product_gallery1" onClose={closeLightbox}>
                  <Carousel
                    currentIndex={currentImage}
                    views={photos.map(x => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </div>
          </div>
          </div>
        </div>
      </section>
            )
        }
        export default Wd_gallery;