import acp1 from "../img/acp_1.jpg";
import acp2 from "../img/acp_45.jpg";
import acp3 from "../img/acp_3.jpg";
import acp4 from "../img/acp_4.jpg";
import acp5 from "../img/acp_5.jpg";
import acp6 from "../img/acp_6.jpg";
import acp7 from "../img/acp_7.jpg";
import acp8 from "../img/acp_8.jpg";
import acp9 from "../img/acp_9.jpg";
import acp10 from "../img/acp_10.jpg";
import acp11 from "../img/acp_11.jpg";
import acp12 from "../img/acp_12.jpg";
import acp13 from "../img/acp_13.jpg";
import acp14 from "../img/acp_14.jpg";
import acp15 from "../img/acp_15.jpg";
import acp16 from "../img/acp_16.jpg";
import acp17 from "../img/acp_17.jpg";
import acp18 from "../img/acp_18.jpg";
import acp19 from "../img/acp_19.jpg";
import acp20 from "../img/acp_20.jpg";
import acp21 from "../img/acp_21.jpg";
import acp22 from "../img/acp_22.jpg";
import acp23 from "../img/acp_23.jpg";
import acp24 from "../img/acp_24.jpg";
import acp25 from "../img/acp_25.jpg";
import acp26 from "../img/acp_26.jpg";
import acp27 from "../img/acp_27.jpg";
import acp28 from "../img/acp_28.jpg";
import acp29 from "../img/acp_29.jpg";
import acp30 from "../img/acp_30.jpg";
import acp31 from "../img/acp_31.jpg";
import acp32 from "../img/acp_32.jpg";
import acp33 from "../img/acp_33.jpeg";
import acp34 from "../img/acp_34.jpg";
import acp35 from "../img/acp_35.jpg";
import acp36 from "../img/acp_36.jpg";
import acp37 from "../img/acp_37.jpg";
import acp38 from "../img/acp_38.jpg";
import acp39 from "../img/acp_39.jpg";
import acp40 from "../img/acp_40.jpg";
import acp41 from "../img/acp_41.jpg";
import acp42 from "../img/acp_42.jpg";
import acp43 from "../img/acp_43.jpeg";
import acp44 from "../img/acp_44.jpg";
import acp45 from "../img/acp_45.jpg";
import acp46 from "../img/acp_46.jpg";
import acp47 from "../img/acp_47.jpg";
import acp48 from "../img/acp_48.jpg";
import acp49 from "../img/acp_49.jpg";
import acp50 from "../img/acp_50.jpg";
import acp51 from "../img/acp_51.jpg";
import acp52 from "../img/acp_52.jpg";
import acp53 from "../img/acp_53.jpg";
import acp54 from "../img/acp_54.jpg";
import acp55 from "../img/acp_55.jpg";
import acp56 from "../img/acp_56.jpg";
import acp57 from "../img/acp_57.jpg";
import acp58 from "../img/acp_58.jpg";
import acp59 from "../img/acp_59.jpg";
import acp60 from "../img/acp_60.jpg";
import acp61 from "../img/acp_61.jpg";
import acp62 from "../img/acp_62.jpg";
import acp63 from "../img/acp_63.jpg";
import acp64 from "../img/acp_64.jpg";
import acp65 from "../img/acp_65.jpg";
import acp66 from "../img/acp_66.jpg";
import acp67 from "../img/acp_67.jpg";
import acp68 from "../img/acp_68.jpg";
import acp69 from "../img/acp_69.jpg";
import acp70 from "../img/acp_70.jpg";
import acp71 from "../img/acp_71.jpg";
import acp72 from "../img/acp_72.jpg";




export const photos = [
    {
      src: acp1,
      width: 4,
      height: 3
    },
    {
      src: acp2,
      width: 1,
      height: 1
    },
    {
      src: acp3,
      width: 3,
      height: 4
    },
    {
      src: acp4,
      width: 3,
      height: 4
    },
    {
      src: acp5,
      width: 3,
      height: 4
    },
    {
      src: acp6,
      width: 4,
      height: 3
    },
    {
      src: acp7,
      width: 3,
      height: 4
    },
    {
      src: acp8,
      width: 4,
      height: 3
    },
    {
      src: acp9,
      width: 4,
      height: 3
    },
    {
        src: acp10,
        width: 4,
        height: 3
      },
      {
        src: acp11,
        width: 1,
        height: 1
      },
      {
        src: acp12,
        width: 3,
        height: 4
      },
      {
        src: acp13,
        width: 3,
        height: 4
      },
      {
        src: acp14,
        width: 3,
        height: 4
      },
      {
        src: acp15,
        width: 4,
        height: 3
      },
      {
        src: acp16,
        width: 3,
        height: 4
      },
      {
        src: acp17,
        width: 4,
        height: 3
      },
      {
        src: acp18,
        width: 4,
        height: 3
      },
      {
        src: acp19,
        width: 4,
        height: 3
      },
      {
        src: acp20,
        width: 4,
        height: 3
      },
      {
        src: acp21,
        width: 1,
        height: 1
      },
      {
        src: acp22,
        width: 3,
        height: 4
      },
      {
        src: acp23,
        width: 3,
        height: 4
      },
      {
        src: acp24,
        width: 3,
        height: 4
      },
      {
        src: acp25,
        width: 4,
        height: 3
      },
      {
        src: acp26,
        width: 3,
        height: 4
      },
      {
        src: acp27,
        width: 4,
        height: 3
      },
      {
        src: acp28,
        width: 4,
        height: 3
      },
      {
          src: acp29,
          width: 4,
          height: 3
        },
        {
          src: acp30,
          width: 1,
          height: 1
        },
        {
          src: acp31,
          width: 3,
          height: 4
        },
        {
          src: acp32,
          width: 3,
          height: 4
        },
        {
          src: acp33,
          width: 3,
          height: 4
        },
        {
          src: acp34,
          width: 4,
          height: 3
        },
        {
          src: acp35,
          width: 3,
          height: 4
        },
        {
          src: acp36,
          width: 4,
          height: 3
        },
        {
          src: acp37,
          width: 4,
          height: 3
        },
        {
          src: acp38,
          width: 4,
          height: 3
        },
        {
          src: acp39,
          width: 4,
          height: 3
        },
        {
          src: acp40,
          width: 1,
          height: 1
        },
        {
          src: acp41,
          width: 3,
          height: 4
        },
        {
          src: acp42,
          width: 3,
          height: 4
        },
        {
          src: acp43,
          width: 3,
          height: 4
        },
        {
          src: acp44,
          width: 4,
          height: 3
        },
        {
          src: acp45,
          width: 3,
          height: 4
        },
        {
          src: acp46,
          width: 4,
          height: 3
        },
        {
          src: acp47,
          width: 4,
          height: 3
        },
        {
            src: acp48,
            width: 4,
            height: 3
          },
          {
            src: acp49,
            width: 1,
            height: 1
          },
          {
            src: acp50,
            width: 3,
            height: 4
          },
          {
            src: acp51,
            width: 1,
            height: 1
          },
          {
            src: acp52,
            width: 4,
            height: 3
          },
          {
            src: acp53,
            width: 1,
            height: 1
          },
          {
            src: acp54,
            width: 1,
            height: 1
          },
          {
            src: acp55,
            width: 1,
            height: 1
          },
          {
            src: acp56,
            width: 1,
            height: 1
          },
          {
            src: acp57,
            width: 3,
            height: 4
          },
          {
            src: acp58,
            width: 1,
            height: 1
          },
          {
            src: acp59,
            width: 1,
            height: 1
          },
          {
            src: acp60,
            width: 1,
            height: 1
          },
          {
            src: acp61,
            width: 4,
            height: 3
          },
          {
            src: acp62,
            width: 1,
            height: 1
          },
          {
            src: acp63,
            width: 1,
            height: 1
          },
          {
            src: acp64,
            width: 3,
            height: 4 
          },
          {
            src: acp65,
            width: 1,
            height: 1
          },
          {
            src: acp66,
            width: 4,
            height: 3
          },
          {
            src: acp67,
            width: 1,
            height: 1
          },
          {
            src: acp68,
            width: 1,
            height: 1
          },
          {
            src: acp69,
            width: 1,
            height: 1
          },
          {
            src: acp70,
            width: 4,
            height: 3
          },
          {
            src: acp71,
            width: 1,
            height: 1
          },
          {
            src: acp72,
            width: 3,
            height: 4
          }
          
  ];
  