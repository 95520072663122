import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../common/header';
import Footer from'../common/footer';
import about_banner from "../../assets/img/about_banner.png";
import Projectslider from './proslide';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

export default class about_us extends React.Component {

  render() {
    return (
      <main>
      <Header></Header>
      <section className="main_heading">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="inner_heading">
                  <h2>About Us</h2>
                  <span></span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bside_link text-right">
                <ul>
                  <li> <Link to="/"><a>Home</a></Link></li><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></i>
                  <li className="page_link"><a href="about_us">About Us</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about_banner section_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <img className="img-fluid" src={about_banner} alt=""></img>
            </div>
          </div>
        </div>
      </section>
      <section className="about_contain">
        <div className="container">
          <div className="row">
            <div className="col-md-12">              
              <div class="dark_head sec_head">
                <h2>About Us</h2>
                <span></span>
              </div>
              <p className="pb-2">Windows and doors are amongst the most frequently stressed or (those are used frequently) components of a house. It handel Wind and rain, strong temperature fluctuations, environmental influences and mechanical stresses constantly.</p>
              <p className="pb-2">Aluminium has been used as a construction material for decades. What began with minimal uses in panels and frames of small houses and buildings has grown to become a style statement providing metallic sheen and class to both residential and commercial spaces.</p>
              <p className="pb-2">Today, aluminium is the ultimate answer to all your window and door needs, regardless of their nature.</p>
              <p className="pb-2">We offers a wide range of the finest quality of aluminium and upvc windows and doors, compatible to a variety of architectural designs.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="about_features set_mob section_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div class="dark_head product_heading">
                <h2>Products</h2>
                <span></span>
              </div>
              <ul className="about_list for_listdote">
                  <li> <span></span>Superior energy efficiency</li>
                  <li> <span></span>Security features</li>
                  <li> <span></span>Self-adjusting capabilities</li>
                  <li> <span></span>Privacy</li>
                  <li> <span></span>Anti-Pollution</li>
                  <li> <span></span>Beautiful structures in any colour.</li>
                  <li> <span></span>Weather Resistant</li>
                </ul>
            </div>
          </div>
        </div>
      </section>
      <Projectslider></Projectslider>
      <Footer></Footer>
      </main>
    );
  }
}