import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../common/header';
import Footer from'../common/footer';
import Wd_gallery from'../common/wd_gallery';
import home4 from "../../assets/img/banner_slider/home4.png";
import window_banner from "../../assets/img/window_banner.png";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faAngleRight, faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default class Aluminium_windows extends React.Component {

  render() {
    return (
      <main>
      <Header></Header>
      <section className="main_heading">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="inner_heading">
                  <h2>Products</h2>
                  <span></span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bside_link text-right">
                <ul>
                <li> <Link to="/"><a>Home</a></Link></li><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></i>
                <li className="page_link"><a href="aluminium_windows">Aluminium Windows</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="window_banner liset_section for_window">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div class="dark_head product_heading">
                <h2>Aluminium Windows </h2>
                <span></span>
              </div>
              <img className="img-fluid" src={home4} alt=""></img>
              <p>Aluminium is such a durable material that it is weatherproof and corrosion resistant, lasting for decades, reducing your overall maintenance expense. Since our aluminium windows come powder coated</p>
            </div>
          </div>
        </div>
      </section>
      <section className="about_features window_list liset_section list_bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div class="dark_head product_heading">
                <h2>Benefits of using Aluminium Windows like </h2>
                <span></span>
              </div>
              <ul className="about_list">
                <li><span>1</span>DURABILITY & LONGEVITY</li>
                <li><span>2</span>MAINTENANCE FREE</li>
                <li><span>3</span>MODERN</li>
                <li><span>4</span>COLORS</li>
                <li><span>5</span>MULTIPLE OPTIONS</li>
                <li><span>6</span>PERFORMANCE</li>
                <li><span>7</span>Thermal Break</li>
              </ul>
            </div>
            <div className="col-md-6">
              <div class="dark_head product_heading for_moblist">
                <h2>Type of Aluminium windows: </h2>
                <span></span>
              </div>
              <ul className="about_list for_listdote">
                <li> <span></span>Aluminium Glass to Glass Window</li>
                <li> <span></span>Aluminium Tilt & Turn Window</li>
                <li> <span></span>Aluminium French Window</li>
                <li> <span></span>Aluminium Top Hung Window</li>
                <li> <span></span>Aluminium Sliding Window</li>
                <li> <span></span>Aluminium Side Hung Window</li>
                <li> <span></span>Aluminium Fixed Window</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <Wd_gallery></Wd_gallery> */}
      <Footer></Footer>
      </main>
    );
  }
}