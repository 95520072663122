import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../common/header';
import Footer from'../common/footer';
import Wd_gallery from'../common/wd_gallery';
import window_banner from "../../assets/img/window_banner.png";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default class Upvc_doors extends React.Component {

  render() {
    return (
      <main>
      <Header></Header>
      <section className="main_heading">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="inner_heading">
                  <h2>Products</h2>
                  <span></span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bside_link text-right">
                <ul>
                  <li><Link to="/"><a>Home</a></Link></li><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></i>
                  <li className="page_link"><a href="#">UPVC Doors</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="window_banner liset_section for_window">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div class="dark_head product_heading">
                <h2>UPVC Doors </h2>
                <span></span>
              </div>
              <img className="img-fluid" src={window_banner} alt=""></img>
              <p>UPVC or PVCu is a form of plastic and stands for Unplasticised Polyvinyl Chloride. It is also known as rigid PVC due to the fact that it is hard and not flexible. It is a resistant from of PVC that is often used for pipework and window frames</p>
              <p className="pt-2">It is a long-lasting building material often used for window frames as a substitute for painted wood.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="about_features window_list liset_section list_bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div class="dark_head product_heading">
                <h2>Benefits of using uPVC for window</h2>
                <span></span>
              </div>
              <ul className="about_list">
                <li><span>1</span>Low cost</li>
                <li><span>2</span>Low maintenance</li>
                <li><span>3</span>Great performance</li>
                <li><span>4</span>Easy to Maintain</li>
                <li><span>5</span>Thermal and Acoustic-Insulation</li>
                <li><span>6</span>Energy-Efficient and Eco-Friendly</li>
                <li><span>7</span>Durable</li>
                <li><span>8</span>Provides Security</li>
              </ul>
            </div>
            <div className="col-md-6">
              <div class="dark_head product_heading for_moblist">
                <h2>Type of UPVC Doors</h2>
                <span></span>
              </div>
              <ul className="about_list for_listdote">
                <li> <span></span>UPVC Doors</li>
                <li> <span></span>UPVC Slide & Fold Door</li>
                <li> <span></span>UPVC Casement Door</li>
                <li> <span></span>UPVC Lift & Sliding Door</li>
                <li> <span></span>UPVC Sliding Door</li>
                <li> <span></span>UPVC French Door</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
      </main>
    );
  }
}