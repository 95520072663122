import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../common/header';
import Footer from'../common/footer';
import port_1 from "../../assets/img/port_1.png";
import port_2 from "../../assets/img/port_2.png";
import port_3 from "../../assets/img/port_3.png";
import port_4 from "../../assets/img/port_4.png";
import port_5 from "../../assets/img/port_5.png";
import we_do_banner from "../../assets/img/acp_64.jpg";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faAngleRight, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

export default class what_we_do extends React.Component {

  render() {
    return (
      <main>
      <Header></Header>
      <section className="main_heading">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="inner_heading">
                  <h2>What We Do</h2>
                  <span></span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bside_link text-right">
                <ul>
                  <li> <Link to="/"><a>Home</a></Link></li><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></i>
                  <li className="page_link"><a href="what_we_do">What We Do</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="window_banner portfolios for_pers">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div class="dark_head product_heading">
                <h2>Facade Solutions</h2>
                <span></span>
              </div>
              <p className="for_last">Being well aware of facade is playing a critical role in the design process, we can offer a facade concept development that combines cost effective technical expertise with functional facade design solutions by working directly with the architects.</p>
              <img className="img-fluid" src={we_do_banner} alt=""></img>
            </div>
          </div>
        </div>
      </section>
      <section className="about_features we_do_img glass_list section_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
            <div className="dark_head product_heading">
              <h2>Manufacturing: </h2>
                <span></span>
            </div>
              <p className="for_last">We manufacture windows and Doors of Aluminium and UPVC, Alarge variety of window & doors options are available with the latest technology.<br/>Casement windows, tilt&turn windows, sliding and awning windows, hinged doors, sliding doors, folding doors are available with practically endless choices and colors.</p>
            <div className="potfolio_main">
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6">
                      <img className="img-fluid" src={port_1} alt=""></img>
                    </div>
                    <div className="col-md-6">
                      <img className="img-fluid" src={port_2} alt=""></img>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <img className="img-fluid" src={port_3} alt=""></img>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <img className="img-fluid" src={port_5} alt=""></img>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <img className="img-fluid" src={port_1} alt=""></img>
                </div>
                <div className="col-md-8">
                  <img className="img-fluid" src={port_4} alt=""></img>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
      </main>
    );
  }
}