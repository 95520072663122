import wd1 from "../img/wd_1.jpeg";
import wd2 from "../img/wd_2.jpg";
import wd3 from "../img/wd_3.jpeg";
import wd4 from "../img/wd_4.jpeg";
import wd5 from "../img/wd_5.jpeg";
import wd6 from "../img/wd_6.jpeg";
import wd7 from "../img/wd_7.jpeg";
import wd8 from "../img/wd_8.jpeg";
import wd9 from "../img/wd_9.jpeg";
import wd10 from "../img/wd_10.jpg";
import wd11 from "../img/wd_11.jpeg";


export const photos = [
    {
      src: wd1,
      width: 3,
      height: 4
    },
    {
      src: wd2,
      width: 4,
      height: 3
    },
    {
      src: wd3,
      width: 3,
      height: 4
    },
    {
      src: wd4,
      width: 4,
      height: 3
    },
    {
      src: wd5,
      width: 4,
      height: 3
    },
    {
        src: wd6,
        width: 4,
        height: 3
      },
      {
        src: wd7,
        width: 1,
        height: 1
      },
      {
        src: wd8,
        width: 3,
        height: 4
      },
      {
        src: wd9,
        width: 3,
        height: 4
      },
      {
        src: wd10,
        width: 3,
        height: 4
      },
      {
        src: wd11,
        width: 3,
        height: 4
      }
          
  ];
  