import $ from "jquery";

$(function() {
    $(window).on("scroll", function() {
        if($(window).scrollTop() > 50) {
            $(".Header").addClass("activeHeader");
        } else {
            //remove the background property so it comes transparent again (defined in your css)
           $(".Header").removeClass("activeHeader");
        }
    });
});

// function toggleDropdown (e) {

//    // alert("hii");
//     const _d = $(e.target).closest('.dropdown'),
//       _m = $('.dropdown-menu', _d);
//     setTimeout(function(){
//       const shouldOpen = e.type !== 'click' && _d.is(':hover');
//       _m.toggleClass('show', shouldOpen);
//       _d.toggleClass('show', shouldOpen);
//       $('[data-toggle="dropdown"]', _d).attr('aria-expanded', shouldOpen);
//     }, e.type === 'mouseleave' ? 300 : 0);
//   }
  
//   $('body')
//     .on('mouseenter mouseleave','.dropdown',toggleDropdown)
//     .on('click', '.dropdown-menu a', toggleDropdown);
    