import React from 'react';

import footerLogo from "../../assets/img/logo2.png";
import footer_imgs from "../../assets/img/footer.png";
import pro_1 from "../../assets/img/glass_2.jpg";
import pro_3 from "../../assets/img/acp_1.jpg";
import pro_4 from "../../assets/img/acp_49.jpg";
import pro_5 from "../../assets/img/project.png";
import pro_6 from "../../assets/img/wd_9.jpeg";
import pro_2 from "../../assets/img/glass_10.jpg";

import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';

export default class Footer extends React.Component {
//     state = {
//         CategoryList: [],
//         SubCategoryList:[],
//         SubCategoryListStore:[],
//         userID:'',
//         newArraylist:[]
//       }
//   componentDidMount() {

//       const sendData = {
//       };

//       axios({
//           method: 'get',
//           url: API_BASE_URL + "category_list/",
        
//          })
//           .then(response => {
//               this.setState({ CategoryList: response.data.result })
//               this.setState({ SubCategoryList: response.data.result1 })
//               //this.handle()
//           })
//   }
  
//   handle(){
//       for (let i=0; i <=this.state. newArraylist.length-1; i++)
//       {
//         let dataId = this.state.newArraylist[i]
//       axios({
//           method: 'get',
//           url: API_BASE_URL + "sub_category_list/?category_id=" +dataId,
//           //   data: sendData
//       })
//           .then(response => {
//               this.setState({ SubCategoryList: response.data.result })
//           })
//       }
//   }

  render() {
  
     return(
       
		<footer>		
          <div className="footer_section2">
              <div className="container">
                  <div className="row">
                      <div className="col-md-3">
                          <div className="footer-widget">
                              <div className="footer_logo"><img src={footerLogo} alt=""/></div>
                              <div className="fw-text">
                                <div class="footer-contact-list footer_pera mt-20">
                                    <p>Windows and doors are amongst the most frequently stressed or (those are used frequently) components of a house</p>
                                </div>
                                    <ul class="social_linksss">
                                        <li><i className="" aria-hidden="true"><FontAwesomeIcon icon={faFacebookF}></FontAwesomeIcon></i></li>
                                        <li><i className="" aria-hidden="true"><FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon></i></li>
                                        <li><i className="" aria-hidden="true"><FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon></i></li>
                                        <li><i className="" aria-hidden="true"><FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon></i></li>
                                    </ul>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-2">
                          <div className="footer-widget">
                              <div className="fw-title mb-25">
                                  <h5>Home</h5>
                              </div>
                              <div className="fw-links">
                                  <ul className="quick">
                                    {/* <li><a href="#">About Us</a></li> */}
                                    <li><Link to="about_us"> <a>About Us</a></Link></li>
                                    <li><Link to="directors"> <a>Board of Directors</a></Link></li>
                                    {/* <li><a href="#">Board Of Directors</a></li> */}
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-2">
                          <div className="footer-widget">
                              <div className="fw-title mb-25">
                                  <h5>Products</h5>
                              </div>
                              <div className="fw-links">
                                  <ul className="quick">
                                      {/* <li><a href="#">Aluminum Windows</a></li> */}
                                      <li><Link to="aluminium_windows"> <a>Aluminium Windows</a></Link></li>
                                      <li><Link to="upvc_windows"> <a>UPVC Windows</a></Link></li>
                                      <li><Link to="Aluminum_doors"> <a>Aluminium Doors</a></Link></li>
                                      <li><Link to="upvc_doors"> <a>UPVC Doors</a></Link></li>
                                      {/* <li><a href="#">Aluminum Doors</a></li> */}
                                      {/* <li><a href="#">Glass</a></li> */}
                                      <li><Link to="glass"> <a>Glass</a></Link></li>
                                      <li><Link to="acp"> <a>ACP</a></Link></li>
                                      {/* <li><a href="#">ACP </a></li> */}
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-5">
                          <div className="footer-widget">
                              <div className="fw-title mb-25">
                                  <h5>New Projects</h5>
                              </div>
                              <div className="footer_imgss">
                                <img src={pro_1} alt=""></img>
                                <img src={pro_6} alt=""></img>
                                <img src={pro_2} alt=""></img>
                              </div>
                              <div className="footer_imgss">
                                <img src={pro_5} alt=""></img>
                                <img src={pro_3} alt=""></img>
                                <img src={pro_4} alt=""></img>
                              </div>
                          </div>
                      </div>
                      <div class="col-md-12">
                          <div class="border_custm"></div>
                      </div>
                     
                  </div>
                  <div class="row">
                      <div class="col-md-12 copy_right_area">
                          <span>Copyright © 2021 Brightget. All Rights Reserved.</span>
                      </div>
                  </div>
              </div>
          </div>
        </footer>
      )
    }
  }