import React from 'react';
import logo from "../../assets/img/logo1.png";
import logo2 from "../../assets/img/logo2.png";
import {Link} from "react-router-dom";
import "../../assets/js/main.js";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
export default class Header extends React.Component {
  componentDidMount() {
  
  }
  render() {
    return(
     
      <header class="Header">
      <div class="container-fluid width_custom mobile_header">
        <nav className="navbar navbar-expand-lg navbar-light custom_p">
          <a className="navbar-brand" href="#">
            <img className="header_logo1" src={logo} alt=""/>
            <img className="header_logo2" src={logo2} alt=""/>
          </a>
          {/* <div class="form-group has-search">
            <span class="form-control-feedback"><i className="" aria-hidden="true"><FontAwesomeIcon icon={faSearch}></FontAwesomeIcon></i></span>
            <input type="text" class="form-control" placeholder="Search"/>
          </div> */}
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
        
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
         
            <ul className="navbar-nav ml-auto">
              <li className="nav-item dropdown">
                <div class="dropdown custom_drop_down">
                  <Link to="/">  
                  <a  class="nav-link dropdown-toggle" id="dropdownMenuButton"  role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Home </a></Link> 
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <Link to="about_us"><a class="dropdown-item">About US</a></Link> 
                  <Link to="directors"><a class="dropdown-item">Board of Directors</a></Link> 
                  </div>
                </div>
              </li>
              <li className="nav-item dropdown">
                <div class="dropdown custom_drop_down mob_setup">
                <Link to="products"> <a  class="nav-link dropdown-toggle" id="dropdownMenuButton"  role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Products
                  </a></Link> 
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <Link to="aluminium_windows"> <a class="dropdown-item">Aluminium Windows</a></Link>
                      <Link to="aluminum_doors"> <a class="dropdown-item">Aluminium Doors</a></Link>
                      <Link to="glass"><a class="dropdown-item">Glass</a></Link> 
                      <Link to="acp"> <a class="dropdown-item">ACP </a></Link>
                      <Link to="upvc_windows"> <a class="dropdown-item">UPVC Windows </a></Link>
                      <Link to="upvc_doors"> <a class="dropdown-item">UPVC Doors</a></Link>
                  </div>

                </div>
              </li>
             
              <li className="nav-item">
                <Link to="our_project">  <a  class="nav-link">
                     Projects
                  </a></Link>
              </li>
              <li className="nav-item active">
                <Link to="portfolios">  <a class="nav-link">
                  Portfolios
                </a></Link>
              </li>
              <li className="nav-item"> 
              <Link to="what_we_do"><a  class="nav-link">
                  What We Do
                </a></Link>
              </li>
              <li className="nav-item">
              <Link to="contact"> <a class="nav-link">
                  Contact Us
                </a></Link>
              </li>
             
            </ul>
           
          </div>
        </nav>
      </div>
    </header>
    )
  }
}