import React from 'react';

class thankyou extends React.Component {

//   constructor (props) {
//     super (props)

// }


  render() {
    return <div>

        <form>
        <h1>thank you</h1>

        </form>
        </div>
  }
}

export default thankyou