import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Home from './components/home/Home';
import about_us from './components/home/about_us';
import Bannerslider from './components/home/bannerslider';
import directors from './components/home/directors';
import products from './components/home/products';
import our_project from './components/home/our_project';
import Projectslider from './components/home/proslide';
import Aluminium_windows from './components/home/aluminum_windows';
import Upvc_windows from './components/home/upvc_windows';
import Upvc_doors from './components/home/upvc_doors';
import aluminum_doors from './components/home/aluminum_doors';
import acp from './components/home/acp';
import portfolios from './components/home/portfolios';
import what_we_do from './components/home/what_we_do';
import glass from './components/home/glass';
import contact from './components/home/contact';
import thankyou from './components/home/thankyou';
import Galleryview from './components/common/gallery';
import Wd_gallery from './components/common/wd_gallery';
import D_gallery from './components/common/d_gallery';
import Glass_gallery from './components/common/glass_gallery';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/index.css";
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
       // alert("sdfs");
        window.scrollTo(0, 0);
      }
    }
  
    render() {
      return this.props.children;
    }
  }
  
  const Scroll = withRouter(ScrollToTop);



class Root extends React.Component {

    render() {

        return(

           <BrowserRouter basename={'/'} >
            
            <Scroll>
						<Switch>
          
              <Route exact={true} path="/" component={Home} />
              <Route path="/bannerslider" component={Bannerslider} />
              <Route path="/bannerslider" component={Bannerslider} />
              <Route path="/directors" component={directors} />
              <Route path="/proslide" component={Projectslider} />
              <Route path="/about_us" component={about_us} />
              <Route path="/products" component={products} />
              <Route path="/our_project" component={our_project} />
              <Route path="/aluminium_windows" component={Aluminium_windows} />
              <Route path="/upvc_windows" component={Upvc_windows} />
              <Route path="/upvc_doors" component={Upvc_doors} />
              <Route path="/aluminum_doors" component={aluminum_doors} />
              <Route path="/glass" component={glass} />
              <Route path="/acp" component={acp} />
              <Route path="/portfolios" component={portfolios} />
              <Route path="/what_we_do" component={what_we_do} />
              <Route path="/contact" component={contact} />
              <Route path="/thankyou" component={thankyou} />
              <Route path="/gallery" component={Galleryview} />
              <Route path="/glass_gallery" component={Glass_gallery} />
              <Route path="/wd_gallery" component={Wd_gallery} />
              <Route path="/d_gallery" component={D_gallery} />

            </Switch>
            </Scroll>
         
					 </BrowserRouter>

    	);
    }
}

ReactDOM.hydrate(<Root />, document.getElementById('root'))
