import React from "react";
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../common/header';
import Footer from'../common/footer';
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";




export default class contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        // name: '',
        // email: '',
        // company_name: '',
        // contact_number: '',
        // message: '', 
        value: "",
        showform: false,
        input: {},
        errors:{},
        MessageSubmit: "thankyou"
      }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    }

handleChange(evt) {
        const value = evt.target.value;
        console.log(evt.target.value)
        this.setState({ [evt.target.name]: evt.target.value })
        // let input = this.state.input;
        // input[evt.target.name] = evt.target.value;
        // this.setState({ input });

  }
    
handleSubmit = event => {
        event.preventDefault();

        // event.target.reset();
// if (this.validate()) {

// let input = {};
// input["email"] = "";
// input["name"] = "";
// input["contact_number"] = "";
// input["company_name"] ="";
// input["message"] = "";


          const sendData = {

          name: this.state.name,
          email: this.state.email,
          message: this.state.message,
          contact_number: this.state.contact_number,
          company_name: this.state.company_name
           
      };
        
        var sendDATA = new FormData();
        sendDATA.append('name', this.state.name);
        sendDATA.append('email', this.state.email);
        sendDATA.append('message', this.state.message);
        sendDATA.append('contact_number', this.state.contact_number);
        sendDATA.append('company_name', this.state.company_name);

        axios({
        method: 'post',
        crossDomain: true,
     
    
        url:"http://www.brightget.com/api/contactus.php",
        data: sendDATA
        }).then(response => {
          console.log(response.data)
         if (response.data.success==200) {
           
          //    localStorage.setItem('name',this.state.name)
          //    localStorage.setItem('contact_number',this.state.contact_number)
          //    localStorage.setItem('company_name',this.state.company_name)
          //    localStorage.setItem('email',this.state.email)
          //    localStorage.setItem('message',this.state.message)
            
             this.setState({showform: true })
            //  this.props.history.push("thankyou");
          }


           //if(response.data.success==false){
          //   if (response.data.message=="Error"){
          //  this.props.history.push("thankyou");
          // }}
          console.log(this.data);
        

}).catch((error) => {

});
// }
}
// validatePhone(phone)

//   {
//   var re =/^[0-9\b]+$/;
//   return re.test(phone);
//   }


    validate() {

     // alert("hiii");

    let input = this.state.input;
    let errors = {};
    let isValid = true;



          // if (!input["name"]) {
          // isValid = false;  
          // errors["name"] = "Please enter number.";
          // }

          // if (!input["message"]) {
          // isValid = false;  
          // errors["message"] = "Please enter message.";
          // }

          // if (!input["company_name"]) {
          // isValid = false;  
          // errors["company_name"] = "Please enter company name.";
          // }

          // if (!input["contact_number"]) {
          // isValid = false;
          // errors["contact_number"] = "Please enter your phone no.";
          // }
          // if(typeof input["contact_number"] !== "undefined"){
          //   if(!input["contact_number"].match(/^[0-9\b]+$/)){
          //   isValid = false;
          //   errors["contact_number"] = "Please enter only numbers";
          //   }        
          // }
          // if (typeof input["contact_number"] !== "undefined") {
          //   const re = /^\S*$/;
          //   if (input["contact_number"].length <= 9 || !re.test(input["contact_number"])) {
          //   isValid = false;
          //   errors["contact_number"] = "Please enter valid phone no.";
          //   }
          // }

          // if (!input["email"]) {
          // isValid = false;
          // errors["email"] = "Please enter your email address.";
          // }
          // if (typeof input["email"] !== "undefined") {
          // var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          // if (!pattern.test(input["email"])) {
          // isValid = false;
          // errors["email"] = "Please enter valid email address.";
          // }
          // }



    this.setState({ errors: errors });
    return isValid;
}



  render(){
 
    return (
      <main>
      <Header></Header>
      <section className="main_heading">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="inner_heading">
                  <h2>Contact us</h2>
                  <span></span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bside_link text-right">
                <ul>
                <li> <Link to="/"><a>Home</a></Link></li><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></i>
                 <li className="page_link"><a href="#">Contact us</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="window_banner portfolios section_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div class="dark_head product_heading">
                <h2>Get In Touch</h2>
                <span></span>
              </div>
              <p>Contact form needs with below fields: </p>

              <form onSubmit={this.handleSubmit} method="post">

                <div className="contact_form">
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input type="name" class="form-control" name="name"  id="#" placeholder="Name" onChange={this.handleChange} required
                      />
                     {/* <div className="text-danger" >{this.state.errors.name}</div>  */}

                    </div>
                    <div className="col-md-6 form-group">
                      <input type="name" class="form-control" name="company_name"  id="#" placeholder="Company Name" onChange={this.handleChange}  required />
                      {/* <div className="text-danger" >{this.state.errors.company_name}</div> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input type="email" class="form-control" name="email"  id="#" placeholder="Email" onChange={this.handleChange} required />
                      {/* <div className="text-danger" >{this.state.errors.email}</div> */}
                      
                    </div>
                    <div className="col-md-6 form-group">
                      <input type="number" class="form-control" name="contact_number" id="#" placeholder="Contact  Number" onChange={this.handleChange} required />
                      {/* <div className="text-danger" >{this.state.errors.contact_number}</div> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <textarea type="text" class="form-control" rows="5" name="message"  id="comment" placeholder="Interested in Comments..." onChange={this.handleChange} required ></textarea>
                      {/* <div className="text-danger" >{this.state.errors.message}</div> */}
                    </div>
                  </div>
                  <div class="contact_btn text-center"><button class="all_btn" type="submit">Submit</button></div>
  
                    {/* <h3 class="text-center">{this.state.MessageSubmit}</h3> */}
                </div>

              </form>
            </div>   
          </div>
        </div>
      </section>
      <section className=" contact_detail section_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="contact_mob">
                <h4>Contact Us</h4>
                <p>+91 9826823312</p>
                <p>+91 9301992993</p>
              </div>
            </div>
        
            <div className="col-md-4">
              <div className="contact_mob">
                <h4>Email</h4>
                <p>pradeep@brightget.com</p>
                <p>vishal@brightget.com</p>
              </div>
            </div>
           
            <div className="col-md-4">
              <div className="contact_mob">
                <h4>Contact Us</h4>
                <p>Vision Fab Systems 470-B Tulsi Nagar Near RR Avenue colony Indore (MP) Pin- 452010</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact_map">
        <div className="map_section">
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117763.55154163977!2d75.79380949777496!3d22.724115838560706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fcad1b410ddb%3A0x96ec4da356240f4!2sIndore%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1612174688044!5m2!1sen!2sin" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
         */}

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.333512575471!2d75.91450131484552!3d22.75300103205894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39631d58b2e92203%3A0x9683a4cf0d17e9d!2s470%2C%20Tulsi%20Nagar%20Rd%2C%20Mahalaxmi%20Nagar%2C%20Indore%2C%20Madhya%20Pradesh%20452010!5e0!3m2!1sen!2sin!4v1612966961555!5m2!1sen!2sin" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        
        </div>
      </section>
      <Footer></Footer>
      </main>


    );
  }}