
import React from 'react';
import Slider from "react-slick"; 

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { API_BASE_URL } from '../../config/api';
// import { BASE_URL } from '../../config/api';
import testi_img from "../../assets/img/test_img.jpeg";
class Testimonial extends React.Component {
  
  // state = {
  //   TestimonialList: []
  // }
  // componentDidMount() {
  //   axios.get(API_BASE_URL + `testimonial_view/`)
  //     .then(res => {
  //       this.setState({TestimonialList: res.data.queryset});

  //     })
  // }

  render() {
        var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay:false,
        autoplaySpeed:3000,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
             
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }]
        };

    // const TestimonialListData =
    //   this.state.TestimonialList.map((data) => {
    //     return (
    //         <div className="testimonial_slider">
    //           <div className="testi_content_slide">
    //             <div className="testi_content">
    //               <p> lorem ipsum dummy contente lorem ipsum dummy contente lorem ipsum dummy contente.</p>
    //               <img src={BASE_URL + data.image} alt="" />
    //               <h6>xyz xyz</h6>
    //               <span>co_public relation</span>
    //             </div>
    //           </div>
    //         </div>
    //      )
    //   })

    return (
        
<div>


        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="dark_head text-center">
                <h2>Testimonial</h2>
                <span></span>
              </div>
            </div>
          </div>
        </div>

       
        <div className="container-fluid width_custom">
        
          <div className="row">
         
            <div className="col-md-12 mt-4">
              <Slider {...settings}>               
              <div className="testimonial_slider">
                <div className="testi_content_slide">
                  <div className="testi_content">
                    <p> I’m really amazed with the way in which work was done. They really made my home complete with the superb interior design and work.</p>
                    <img src={testi_img} alt="" />
                    <h6>Amit Patidar</h6>
                    {/* <span>co_public relation</span> */}
                  </div>
                </div>
              </div>
              <div className="testimonial_slider">
                <div className="testi_content_slide">
                  <div className="testi_content">
                    <p>The designers at Homzinterio are just amazing. I decided to go ahead with them after visiting a lot of interior designers. I am extremely happy with my choice.</p>
                    <img src={testi_img} alt="" />
                    <h6>Shubham Singh</h6>
                    {/* <span>co_public relation</span> */}
                  </div>
                </div>
              </div>
              <div className="testimonial_slider">
                <div className="testi_content_slide">
                  <div className="testi_content">
                    <p>What we saw on the computer screen was delivered a 100% at our house. The fit and finish of each of the component is fabulous. The high quality of the materials used is visible and felt while usage. </p>
                    <img src={testi_img} alt="" />
                    <h6>Sawan Yadav</h6>
                    {/* <span>co_public relation</span> */}
                  </div>
                </div>
              </div>
              <div className="testimonial_slider">
                <div className="testi_content_slide">
                  <div className="testi_content">
                    <p>The entire project was wrapped up in 45 days. The fitment at my home was completed in 15 days flat. it was just an amazing & unbelievable experience. Thanks to the whole team.</p>
                    <img src={testi_img} alt="" />
                    <h6>Preeti Sharma</h6>
                    {/* <span>co_public relation</span> */}
                  </div>
                </div>
              </div>
              <div className="testimonial_slider">
                <div className="testi_content_slide">
                  <div className="testi_content">
                    <p> I’m really amazed with the way in which work was done. They really made my home complete with the superb interior design and work.</p>
                    <img src={testi_img} alt="" />
                    <h6>Amit Patidar</h6>
                    {/* <span>co_public relation</span> */}
                  </div>
                </div>
              </div>
              </Slider>
            </div>       
          </div>
        </div>

  </div>
);
  }
}

export default Testimonial
