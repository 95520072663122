import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../common/header';
import Footer from'../common/footer';
import Testimonial from'../home/testimonial';
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faAngleRight, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Galleryview from "../common/gallery";
import Glass_gallery from "../common/glass_gallery";
import Wd_gallery from "../common/wd_gallery";
import D_gallery from "../common/d_gallery";
import {Link} from "react-router-dom";

export default class portfolios extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      show1: true,
      show2: false,
      show3: false,
      show4: false
    }

    this.handleShow1 = this.handleShow1.bind(this);
    this.handleShow2 = this.handleShow2.bind(this);
    this.handleShow3 = this.handleShow3.bind(this);
    this.handleShow4 = this.handleShow4.bind(this);
}

handleShow1() {

this.setState({ show1: true, show2: false, show3: false, show4: false });
}
handleShow2() {


this.setState({ show1: false, show2: true, show3: false, show4: false });
}
handleShow3() {


this.setState({ show1: false, show2: false, show3: true, show4: false });
}
handleShow4() {

  
  this.setState({ show1: false, show2: false, show3: false, show4: true});
}

  render() {
    return (
      <main>
      <Header></Header>
      <section className="main_heading">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="inner_heading">
                  <h2>Portfolios</h2>
                  <span></span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bside_link text-right">
                <ul>
                   <li> <Link to="/"><a>Home</a></Link></li><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></i>
                   <li className="page_link"><a href="portfolios">Portfolios</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <section className="window_banner portfolios section_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="port_main">
                <div className="row">
                  <div className="col-md-12">
                    <div className="port_btns">
                      <button className="left_margin" onClick={this.handleShow1}>Aluminium Windows</button>
                      <button onClick={this.handleShow2}>Aluminium Doors</button>
                      <button onClick={this.handleShow3}>Glass</button>
                      <button onClick={this.handleShow4}>ACP</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {this.state.show4 ?
      <Galleryview></Galleryview>
      : ""}
       {this.state.show3 ?
      <Glass_gallery></Glass_gallery>
      : ""}
       {this.state.show1 ?
      <Wd_gallery></Wd_gallery>
      : ""}
       {this.state.show2 ?
      <D_gallery></D_gallery>
      : ""}
     <section className="portfolios_bg section_padding">
        <Testimonial></Testimonial>
      </section>
      <Footer></Footer>
      </main>
    );
  }
}