
import React from 'react';
import Slider from "react-slick"; 

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from "react-router-dom";
import home1 from "../../assets/img/banner_slider/home1.png";
import home2 from "../../assets/img/banner_slider/home2.png";
import home3 from "../../assets/img/banner_slider/home3.png";
import home4 from "../../assets/img/banner_slider/home4.png";
import home5 from "../../assets/img/banner_slider/home5.png";
import home6 from "../../assets/img/banner_slider/home6.png";
import home7 from "../../assets/img/banner_slider/home7.png";
import home8 from "../../assets/img/banner_slider/home8.png";
class Bannerslider extends React.Component {
  

  render() {
        var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        lazyLoad: 'progressive',
        
        autoplay:true,
        autoplaySpeed:3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "slides",
        responsive: [
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
             
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }]
        };

   

    return (
        
<section className="banner_slider" data-slick>
     
            <Slider {...settings}>
  
                <div className="">
                   <div className="home_banner">
                       <img src={home1} alt=""></img>
                        <div className="home_banner_content">
                            <h2>Tell us your "dream" we will make it "happen".</h2>
                            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the race.</p> 
                           <Link to="contact"> <button className="aaaa">Get in touch</button></Link>
                        </div>
                    </div>
               </div>           
               <div className="">
                   <div className="home_banner">
                       <img src={home2} alt=""></img>
                        <div className="home_banner_content">
                            <h2>Quality work that you want today</h2>
                            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the race.</p> 
                            <Link to="contact"> <button className="aaaa">Get in touch</button></Link>
                        </div>
                    </div>
               </div>
               <div className="">
                   <div className="home_banner">
                       <img src={home3} alt=""></img>
                        <div className="home_banner_content">
                            <h2>Let’s work speak from themselves.</h2>
                            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the race.</p> 
                            <Link to="contact"> <button>Get in touch</button></Link>
                        </div>
                    </div>
               </div>
               <div className="">
                   <div className="home_banner">
                       <img src={home4} alt=""></img>
                        <div className="home_banner_content">
                            <h2>Let Us put Your ideas into focus and reality </h2>
                            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the race.</p> 
                            <Link to="contact"> <button>Get in touch</button></Link>
                        </div>
                    </div>
               </div>
               <div className="">
                  <div className="home_banner">
                      <img src={home5} alt=""></img>
                      <div className="home_banner_content">
                          <h2>Quality is Our Commitment</h2>
                          <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the race.</p> 
                          <Link to="contact"> <button>Get in touch</button></Link>
                      </div>
                  </div>
               </div>
               <div className="">
                  <div className="home_banner">
                      <img src={home6} alt=""></img>
                      <div className="home_banner_content">
                          <h2>A Bright Window Is A Right Window.</h2>
                          <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the race.</p> 
                          <Link to="contact"> <button>Get in touch</button></Link>
                      </div>
                  </div>
               </div>
               <div className="">
                  <div className="home_banner">
                      <img src={home7} alt=""></img>
                      <div className="home_banner_content">
                          <h2>We add a new life to your windows</h2>
                          <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the race.</p> 
                          <Link to="contact"> <button>Get in touch</button></Link>
                      </div>
                  </div>
               </div>
               <div className="">
                  <div className="home_banner">
                      <img src={home8} alt=""></img>
                      <div className="home_banner_content">
                          <h2>We Take Care Of Your Pain</h2>
                          <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the race.</p> 
                          <Link to="contact"><button>Get in touch</button></Link>
                      </div>
                  </div>
               </div>
               </Slider>
            {/* </div>       
          </div>
        </div> */}
      </section>  
);
  }
}

export default Bannerslider
