import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../common/header';
import Footer from'../common/footer';
import home8 from "../../assets/img/banner_slider/home8.png";
import window_banner from "../../assets/img/window_banner.png";
import { FontAwesomeIcon } from '../../../node_modules/@fortawesome/react-fontawesome';
import { faAngleRight, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

export default class glass extends React.Component {

  render() {
    return (
      <main>
      <Header></Header>
      <section className="main_heading">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="inner_heading">
                  <h2>Products</h2>
                  <span></span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bside_link text-right">
                <ul>
                  <li> <Link to="/"><a>Home</a></Link></li><i className="set_myarrow" aria-hidden="true"><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></i>
                  <li className="page_link"><a href="glass">Aluminum Glass</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="window_banner liset_section for_window ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div class="dark_head product_heading">
                <h2>Aluminum Glass</h2>
                <span></span>
              </div>
              <img className="img-fluid" src={home8} alt=""></img>
              <p className="pb-2">Facade is a French origin word meaning the front face. The use of glass in the exterior facades provided more of light and good ambience to the occupant of the building which gave rise to the increasing use of glass. For the architectural point of view the use of glass gave aesthetic view to the building itself.</p>
              <p>A structure supported by aluminum frame work which is made up of Mullion and Transom is called as a curtain wall. Mullion is a vertical support or we may call it as a column, while the transom is a horizontal support likely to a beam. The first curtain walls were made with steel mullions, and the plate glass was attached to the mullions with asbestos or fiber glass modified glazing compound.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="about_features glass_list liset_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div class="dark_head product_heading">
                <h2>Types of Architectural Glass used in Modern Facades & Fenestration:</h2>
                <span></span>
              </div>
              <ul className="about_list">
                <div className="row">
                  <div className="col-md-6">
                    <li><span>1</span>	Clear glass</li>
                    <li><span>2</span>	Sun control glass</li>
                    <li><span>3</span>	Ceramic printed glass</li>
                    <li><span>4</span>	Digitally printed glass</li>
                    <li><span>5</span>	Electrochromic glass/Translucent glass</li>
                    <li><span>6</span>	Annealed glass</li>
                    <li><span>7</span>	Fully-tempered glass (toughened glass)</li>
                    <li><span>8</span>	Laminated Glass</li>
                  </div>
                  <div className="col-md-6">
                    <li><span>9</span>	Insulating glass</li>
                    <li><span>10</span>	Tinted glass</li>
                    <li><span>11</span>	Self-cleaning glass</li>
                    <li><span>12</span>	Fire-rated glass (protective glass)</li>
                    <li><span>13</span>	Low E-coated glass</li>
                    <li><span>14</span>	Frosted glass</li>
                    <li><span>15</span>	Embossed glass</li>
                    <li><span>16</span>	Sandblasted glass</li>
                  </div>
                  <p class="glass_last_pera">Glass facade: Glass Facade is an important element in buildings and the main function of it is to protect and distinct the internal from the external environment conditions, so energy-efficient building designers have started to develop their researches based on this matter. Today, energy usage in buildings have become an important factor due to the limited energy sources, hence, building construction materials such as glass and techniques should be considered accordingly in the design period</p>
                </div>
                
                
              </ul>
            </div>
          </div>
          <div class="" row="">
            <div class="col-md-12 p-0">
                
            </div>
          </div>
        </div>
      </section>
      <section className="about_features window_list liset_section list_bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div class="dark_head product_heading">
                <h2>There are four main Principles of glass facade performance </h2>
                <span></span>
              </div>
              <ul className="about_list for_listdote" itemType="none">
                <li> <span><div></div></span>	Wind</li>
                <li> <span></span>	Visual comfort:</li>
                <li> <span></span>Thermal comfort</li>
                <li> <span></span>Solar radiation</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <Glass_gallery></Glass_gallery> */}
      <Footer></Footer>
      </main>
    );
  }
}